import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "./center"

export function FM07EventTap() {
    const [animate, cycle] = useCycle(
        { scale: 1, rotate: 0 },
        { scale: 1.25, rotate: 90 }
    )

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animate}
                onTap={() => cycle()}
            />
        </Center>
    )
}
