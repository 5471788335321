import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM09Drag() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "grab",
                }}
                drag
                whileTap={{ cursor: "grabbing" }}
            />
        </Center>
    )
}
