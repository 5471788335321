import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

const rows = [0, 1]
const cols = [0, 1]
const size = 70
const padding = 10

export function FM25Grid() {
    return (
        <Center>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: padding,
                }}
            >
                {rows.map(rowIndex => {
                    return cols.map(colIndex => {
                        return (
                            <motion.div
                                style={{
                                    width: size,
                                    height: size,
                                    borderRadius: 20,
                                    backgroundColor: "#fff",
                                }}
                                whileHover={{ scale: 0.8, opacity: 0.5 }}
                                key={`${rowIndex} : ${colIndex}`}
                            />
                        )
                    })
                })}
            </div>
        </Center>
    )
}
