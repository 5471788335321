import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM30FlexboxAnimation() {
    const [state, setState] = useState("both")

    const variants = {
        hidden: {
            width: 0,
        },
        normal: {
            width: 70,
        },
        expanded: {
            width: 150,
        },
    }

    return (
        <Center>
            <div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "space-between",
                    transform: "translateZ(0)",
                }}
            >
                <motion.div
                    style={{
                        width: 70,
                        height: 150,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        cursor: "pointer",
                    }}
                    variants={variants}
                    animate={
                        state === "left"
                            ? "expanded"
                            : state === "both"
                            ? "normal"
                            : "hidden"
                    }
                    onTap={() => {
                        if (state === "both") {
                            setState("left")
                        } else {
                            setState("both")
                        }
                    }}
                />
                <motion.div
                    style={{
                        width: 70,
                        height: 150,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        cursor: "pointer",
                    }}
                    variants={variants}
                    animate={
                        state === "right"
                            ? "expanded"
                            : state === "both"
                            ? "normal"
                            : "hidden"
                    }
                    onTap={() => {
                        if (state === "both") {
                            setState("right")
                        } else {
                            setState("both")
                        }
                    }}
                />
            </div>
        </Center>
    )
}
