import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM26Variants() {
    const variants = {
        variantA: { scale: 1, rotate: 90 },
        variantB: { scale: 1.25, rotate: 0 },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                variants={variants}
                initial="variantA"
                whileHover="variantB"
            />
        </Center>
    )
}
