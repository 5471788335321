import * as React from "react"
import { motion } from "framer-motion"

export function FM21ColorArrays() {
    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#60F",
                display: "flex",
                placeItems: "center",
                placeContent: "center",
            }}
            animate={{ backgroundColor: ["#60F", "#09F", "#FA0"] }}
            transition={{ duration: 2, yoyo: Infinity }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{ rotate: [0, 180] }}
                transition={{ duration: 2, yoyo: Infinity }}
            />
        </motion.div>
    )
}
