import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM28VariantsNesting() {
    const parent = {
        variantA: { scale: 1 },
        variantB: { scale: 1.25 },
    }

    const child = {
        variantA: { bottom: 0, right: 0, rotate: 0 },
        variantB: { top: 0, left: 0, rotate: 180 },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "rgba(255,255,255,0.5)",
                    position: "relative",
                }}
                variants={parent}
                initial="variantA"
                whileHover="variantB"
            >
                <motion.div
                    style={{
                        width: 85,
                        height: 85,
                        borderRadius: "20px 20px 30px 20px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                    }}
                    variants={child}
                    transition={{
                        type: "spring",
                        damping: 10,
                        mass: 0.2,
                        stiffness: 150,
                    }}
                />
            </motion.div>
        </Center>
    )
}
