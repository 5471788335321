import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "./center"

export function FM08EventSequence() {
    const animation = useAnimation()

    async function sequence() {
        await animation.start({ rotate: -90 })
        await animation.start({ scale: 1.5 })
        await animation.start({ rotate: 0 })
        await animation.start({ scale: 1 })
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                onTap={sequence}
                animate={animation}
            />
        </Center>
    )
}
