import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import { Refresh } from "./Refresh";

import { FM01Animate } from "./Framer Motion/01. Animate";
import { FM02AnimateSpring } from "./Framer Motion/02. Animate: Spring";
import { FM03AnimateLoop } from "./Framer Motion/03. Animate: Loop";
import { FM04AnimateReverse } from "./Framer Motion/04. Animate: Reverse";
import { FM05EventWhileTap } from "./Framer Motion/05. Event: WhileTap";
import { FM06EventWhileHover } from "./Framer Motion/06. Event: WhileHover";
import { FM07EventTap } from "./Framer Motion/07. Event: Tap";
import { FM08EventSequence } from "./Framer Motion/08. Event: Sequence";
import { FM09Drag } from "./Framer Motion/09. Drag";
import { FM10DragInertia } from "./Framer Motion/10. Drag: Inertia";
import { FM11DragLocking } from "./Framer Motion/11. Drag: Locking";
import { FM12DragTransform } from "./Framer Motion/12. Drag: Transform";
import { FM13Drag3D } from "./Framer Motion/13. Drag: 3D";
import { FM17Scroll } from "./Framer Motion/17. Scroll";
import { FM18ScrollProgress } from "./Framer Motion/18. Scroll: Progress";
import { FM20ScrollRefresh } from "./Framer Motion/20. Scroll: Refresh";
import { FM21ColorArrays } from "./Framer Motion/21. Color: Arrays";
import { FM22ColorInterpolation } from "./Framer Motion/22. Color: Interpolation";
import { FM23ColorLayered } from "./Framer Motion/23. Color: Layered";
import { FM24Cursor } from "./Framer Motion/24. Cursor";
import { FM25Grid } from "./Framer Motion/25. Grid";
import { FM26Variants } from "./Framer Motion/26. Variants";
import { FM27VariantsStagger } from "./Framer Motion/27. Variants: Stagger";
import { FM28VariantsNesting } from "./Framer Motion/28. Variants: Nesting";
import { FM29Flexbox } from "./Framer Motion/29. Flexbox";
import { FM30FlexboxAnimation } from "./Framer Motion/30. Flexbox: Animation";
import { FM31Keyframes } from "./Framer Motion/31. Keyframes";
import { FM32SVG } from "./Framer Motion/32. SVG";
import { FM33Modal } from "./Framer Motion/33. Modal";
import { FM34Shuffle } from "./Framer Motion/34. Shuffle";
import { FM35SwipeToDelete } from "./Framer Motion/35. Swipe To Delete";
import { FM36Stack3D } from "./Framer Motion/36. Stack 3D";

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function returnComponent(id: number, count: number) {
  switch (id) {
    case 1:
      return <FM01Animate key={count} />;
    case 2:
      return <FM02AnimateSpring key={count} />;
    case 3:
      return <FM03AnimateLoop />;
    case 4:
      return <FM04AnimateReverse />;
    case 5:
      return <FM05EventWhileTap />;
    case 6:
      return <FM06EventWhileHover />;
    case 7:
      return <FM07EventTap />;
    case 8:
      return <FM08EventSequence />;
    case 9:
      return <FM09Drag key={count} />;
    case 10:
      return <FM10DragInertia />;
    case 11:
      return <FM11DragLocking />;
    case 12:
      return <FM12DragTransform />;
    case 13:
      return <FM13Drag3D />;
    case 14:
      return;
    case 15:
      return;
    case 16:
      return;
    case 17:
      return <FM17Scroll />;
    case 18:
      return <FM18ScrollProgress />;
    case 19:
      return;
    case 20:
      return <FM20ScrollRefresh />;
    case 21:
      return <FM21ColorArrays />;
    case 22:
      return <FM22ColorInterpolation />;
    case 23:
      return <FM23ColorLayered />;
    case 24:
      return <FM24Cursor />;
    case 25:
      return <FM25Grid />;
    case 26:
      return <FM26Variants />;
    case 27:
      return <FM27VariantsStagger key={count} />;
    case 28:
      return <FM28VariantsNesting />;
    case 29:
      return <FM29Flexbox />;
    case 30:
      return <FM30FlexboxAnimation />;
    case 31:
      return <FM31Keyframes />;
    case 32:
      return <FM32SVG />;
    case 33:
      return <FM33Modal />;
    case 34:
      return <FM34Shuffle />;
    case 35:
      return <FM35SwipeToDelete key={count} />;
    case 36:
      return <FM36Stack3D />;
  }
}

function QueryParams(props: any) {
  let query = useQuery();

  const id = Number(query.get("id"));

  let background;
  if (id >= 1 && id < 5) {
    background = "#00AAFF";
  } else if (id >= 5 && id < 9) {
    background = "#0088FF";
  } else if (id >= 9 && id < 13) {
    background = "#0066FF";
  } else if (id >= 13 && id < 17) {
    background = "#0044FF";
  } else if (id >= 17 && id < 21) {
    background = "#4400FF";
  } else if (id >= 21 && id < 25) {
    background = "#6600FF";
  } else if (id >= 25 && id < 29) {
    background = "#8800FF";
  } else if (id >= 29 && id < 33) {
    background = "#AA00DD";
  } else if (id >= 33) {
    background = "#AA00BB";
  }

  let refresh = null;
  if (id === 1 || id === 2 || id === 9 || id === 27 || id === 35) {
    refresh = <Refresh onClick={() => props.setCount(props.count + 1)} />;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: background
      }}
    >
      {refresh}
      {returnComponent(id, props.count)}
    </div>
  );
}

export function App() {
  const [count, setCount] = React.useState(0);

  return (
    <>
      <Router>
        <QueryParams count={count} setCount={setCount} />
      </Router>
    </>
  );
}

export default App;
