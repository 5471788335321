import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM11DragLocking() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "grab",
                }}
                drag
                dragDirectionLock
                dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
                dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                dragElastic={0.5}
                whileTap={{ cursor: "grabbing" }}
            />
        </Center>
    )
}
