import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM05EventWhileTap() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                whileTap={{ rotate: 90, scale: 0.75 }}
            />
        </Center>
    )
}
