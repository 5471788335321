import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "./center"

const itemsA = [1, 2, 3, 4]
const itemsB = [3, 1, 4, 2]
const itemsC = [4, 3, 2, 1]
const itemsD = [2, 4, 1, 3]

const spring = {
    type: "spring",
    stiffness: 350,
    damping: 25,
}

export function FM34Shuffle() {
    const [items, setItems] = useCycle(itemsA, itemsB, itemsC, itemsD)

    React.useEffect(() => {
        setTimeout(() => setItems(), 1000)
    }, [items])

    return (
        <Center>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: 10,
                }}
            >
                {items.map(item => (
                    <motion.div
                        style={{
                            width: 75,
                            height: 75,
                            borderRadius: 20,
                            backgroundColor: "#fff",
                        }}
                        key={item}
                        positionTransition={spring}
                    />
                ))}
            </div>
        </Center>
    )
}
