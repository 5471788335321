import * as React from "react"
import { motion, useMotionValue, useTransform } from "framer-motion"

export function FM22ColorInterpolation() {
    const x = useMotionValue(0)
    const background = useTransform(x, [-100, 0, 100], ["#A0D", "#60F", "#0BF"])

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: background,
                display: "flex",
                placeItems: "center",
                placeContent: "center",
            }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 75,
                    backgroundColor: "#fff",
                    x: x,
                    cursor: "grab",
                }}
                drag="x"
                dragConstraints={{
                    right: 0,
                    left: 0,
                }}
                whileTap={{ cursor: "grabbing" }}
            />
        </motion.div>
    )
}
