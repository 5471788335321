import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "./center"

export function FM01Animate() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{ rotate: 360 }}
                transition={{ duration: 2 }}
            />
        </Center>
    )
}
