import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"

export function FM23ColorLayered() {
    const [isHover, setHover] = useState(false)

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#60F",
                display: "flex",
                placeItems: "center",
                placeContent: "center",
            }}
            animate={{ backgroundColor: isHover ? "#08F" : "#60F" }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{
                    scale: isHover ? 0.8 : 1,
                    rotate: isHover ? 90 : 0,
                }}
                onHoverStart={() => {
                    setHover(true)
                }}
                onHoverEnd={() => {
                    setHover(false)
                }}
            />
        </motion.div>
    )
}
