import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Center } from "./center"

export function FM33Modal() {
    const [isVisible, setVisible] = React.useState(true)

    function toggle() {
        setVisible(!isVisible)
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "rgba(255,255,255,0.5)",
                    cursor: "pointer",
                }}
                onTap={toggle}
            >
                <AnimatePresence>
                    {isVisible && (
                        <motion.div
                            style={{
                                width: 80,
                                height: 80,
                                borderRadius: 15,
                                backgroundColor: "#fff",
                                margin: "35px 0 0 35px",
                            }}
                            initial={{ opacity: 0, scale: 0.75 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                        />
                    )}
                </AnimatePresence>
            </motion.div>
        </Center>
    )
}
