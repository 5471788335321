import * as React from "react"
import { motion, useMotionValue, useAnimation } from "framer-motion"
import { Center } from "./center"

const initialItems = [0, 1, 2, 3, 4]
const height = 70
const padding = 10
const size = 150

function Item({ total, index, onDelete }) {
    const controls = useAnimation()

    async function handleDragEnd(event, info) {
        const offset = info.offset.x
        const velocity = info.velocity.x

        if (offset < -100 || velocity < -500) {
            await controls.start({
                x: "-100%",
                transition: { duration: 0.2 },
            })

            onDelete(index)
        } else {
            controls.start({ x: 0, opacity: 1, transition: { duration: 0.5 } })
        }
    }

    return (
        <motion.div
            style={{
                width: 150,
                height: height,
                borderRadius: 20,
                overflow: "hidden",
                marginBottom: total - 1 === index ? 0 : 10,
                willChange: "transform",
                cursor: "grab",
            }}
            whileTap={{ cursor: "grabbing" }}
            positionTransition={{
                type: "spring",
                stiffness: 600,
                damping: 30,
            }}
        >
            <motion.div
                style={{
                    width: size,
                    height: height,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                }}
                drag="x"
                dragDirectionLock
                onDragEnd={handleDragEnd}
                animate={controls}
            />
        </motion.div>
    )
}

export function FM35SwipeToDelete() {
    const y = useMotionValue(0)

    const [items, setItems] = React.useState(initialItems)
    const { top, bottom } = useConstraints(items)
    const controls = useAnimation()
    const totalScroll = getHeight(items)
    const scrollContainer = 150

    function onDelete(index) {
        const newItems = [...items]
        newItems.splice(index, 1)

        const newScrollHeight = getHeight(newItems)
        const bottomOffset = -y.get() + scrollContainer
        const bottomWillBeVisible = newScrollHeight < bottomOffset
        const isScrollHeightLarger = newScrollHeight >= scrollContainer

        if (bottomWillBeVisible && isScrollHeightLarger) {
            controls.start({
                y: -newScrollHeight + scrollContainer,
            })
        }

        setItems(newItems)
    }

    return (
        <Center>
            <div
                style={{
                    width: size,
                    height: size,
                    borderRadius: 30,
                    backgroundColor: "transparent",
                    overflow: "hidden",
                    position: "relative",
                    transform: "translateZ(0)",
                }}
            >
                <motion.div
                    style={{ y: y, height: totalScroll }}
                    drag="y"
                    dragDirectionLock
                    dragConstraints={{ top, bottom }}
                    animate={controls}
                >
                    {items.map((value, index) => {
                        return (
                            <Item
                                total={items.length}
                                index={index}
                                onDelete={onDelete}
                                key={value}
                            />
                        )
                    })}
                </motion.div>
            </div>
        </Center>
    )
}

function getHeight(items) {
    const totalHeight = items.length * height
    const totalPadding = (items.length - 1) * padding
    const totalScroll = totalHeight + totalPadding
    return totalScroll
}

function useConstraints(items) {
    const [constraints, setConstraints] = React.useState({ top: 0, bottom: 0 })

    React.useEffect(() => {
        setConstraints({ top: size - getHeight(items), bottom: 0 })
    }, [items])

    return constraints
}
