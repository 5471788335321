import * as React from "react"
import { Center } from "./center"

export function FM29Flexbox() {
    return (
        <Center>
            <div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        width: 70,
                        height: 150,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                    }}
                />
                <div
                    style={{
                        width: 70,
                        height: 150,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                    }}
                />
            </div>
        </Center>
    )
}
